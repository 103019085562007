<script lang="ts" setup>
import type {Tournament} from '~/types'

defineProps<{ tournament: Tournament }>()
const localePath = useLocalePath()
</script>

<template>
  <QItem :to="localePath(`/tournaments/${tournament.id}`)">
    <q-item-section avatar class="w-20">
      <NuxtImg
        v-if="tournament.image"
        :src="tournament.image.replace('dartsspace.test', 'api.dartsspace.com')"
        :alt="`Tournament logo or poster ${tournament.name} | ${tournament.event_name}`"
        fit="contain"
        class="rounded-lg max-h-20 max-w-10 w-auto"
        width="100"
        height="100"
        placeholder
        quality="80"
        loading="lazy"
      />
    </q-item-section>
    <q-item-section>
      <q-item-label overline>
        {{ tournament.event_name }} ({{ tournament.name }})
      </q-item-label>
      <q-item-label class="flex flex-row gap-2">
        <QBadge color="primary" outline>
          {{ tournament.type }}
        </QBadge>
        <QBadge color="primary" outline>
          {{ tournament.gender }}
        </QBadge>
      </q-item-label>
      <q-item-label caption>
        <TournamentStatusBadge :tournament="tournament" />
      </q-item-label>
      <q-item-label caption>
        <DateTime :date="tournament.starts_at" />
      </q-item-label>
      <q-item-label caption>
        {{ tournament.address_simple }}
      </q-item-label>
      <q-item-label v-if="tournament.complete && tournament.personal_result">
        {{ $t('Your result: ') }} {{ tournament.personal_result }}
      </q-item-label>
    </q-item-section>
    <QItemSection v-if="$q.screen.gt.sm" side>
      <QBtn v-if="!tournament.can_register" size="sm" color="primary">
        {{ $t('View') }}
      </QBtn>
      <QBtn v-else-if="tournament.registered" color="warning" size="sm">
        {{ $t('Manage registration') }}
      </QBtn>
      <QBtn v-else color="positive" size="sm">
        {{ $t('View & Register') }}
      </QBtn>
    </QItemSection>
  </QItem>
</template>
